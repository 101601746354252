@import "/src/styles/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Geologica:wght@200;400;600;800&display=swap");
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  &::-moz-selection {
    /* Code for Firefox */
    color: $lightest;
    background: $main-color;
  }
  &::selection {
    color: $lightest;
    background: $main-color;
  }
}
html {
  overflow: hidden;
  height: 100%;
}
body {
  width: 100%;
  height: 100%;
  overflow: auto;

  background-color: white;
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
li,
label,
input,
textarea {
  font-family: "Geologica", sans-serif;
}
h1,
h2 {
  font-weight: 400;
  font-size: $title-size;
}
h3,
p,
li,
a,
ul,
input,
textarea {
  font-size: $text-size;
}

a {
  text-decoration: none;
  transition: 0.4s all;
  font-weight: 400;
  font-size: $links-size;
  color: $main-color;
  &:visited {
    color: $main-color;
  }
  &:hover {
    color: $links-hover;
  }
}

.section {
  padding: 7vw 5vw 0 5vw;
}

#text-card {
  font-size: $title-size;
}

.Specializations,
.Pros,
.LCS,
.Projects,
.Contacts {
  &__title {
    font-size: $title-size;
    font-weight: 800;
  }
}

// @keyframes fade-in {
//   to {
//     opacity: 100%;
//   }
// }
@media (max-width: $little-screen) {
  h1,
  h2 {
    font-size: $little-screens-title-size;
  }
  h3,
  p,
  li,
  a,
  ul,
  input,
  textarea {
    font-size: $little-screens-text-size;
  }

  .Specializations,
  .Pros,
  .LCS,
  .Projects,
  .Contacts {
    &__title {
      font-size: $little-screens-title-size;
      font-weight: 800;
      padding: 1.5vw 0;
    }
  }

  #text-card {
    font-size: $little-screens-subtitle-size;
  }
}

@media (max-width: $tablets) {
  h1,
  h2 {
    font-size: $tablets-title-size;
  }
  h3,
  p,
  li,
  a,
  ul,
  input,
  textarea {
    font-size: $little-screens-text-size;
  }

  .Specializations,
  .Pros,
  .LCS,
  .Projects,
  .Contacts {
    &__title {
      font-size: $tablets-title-size;
    }
  }

  #text-card {
    font-size: $tablets-title-size;
  }
}
@media (max-width: 600px) {
  #text-card {
    font-size: 0.8rem;
  }
}

@media (max-width: $mobile) {
  h1,
  h2 {
    font-size: $mobile-title-size;
  }
  h3,
  p,
  li,
  a,
  ul,
  input,
  textarea {
    font-size: $mobile-text-size;
  }

  .Specializations,
  .Pros,
  .LCS,
  .Projects,
  .Contacts {
    &__title {
      text-align: center;
      font-size: $mobile-title-size;
      padding-bottom: 6vw;
    }
  }

  #text-card {
    text-align: left;
    margin-left: 1rem;
    font-size: $mobile-subtitle-size;
    padding: 4vw 2vw;
    width: 100%;
  }
}

@media (max-width: $thinnest) {
  h1,
  h2 {
    font-size: $thinnest-title-size;
  }
  h3,
  p,
  li,
  a,
  ul,
  input,
  textarea {
    font-size: $mobile-text-size;
  }

  .Specializations,
  .Pros,
  .LCS,
  .Projects,
  .Contacts {
    &__title {
      font-size: $thinnest-title-size;
      padding-bottom: 6vw;
    }
  }

  #text-card {
    font-size: $thinnest-subtitle-size;
  }
}
