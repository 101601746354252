@import "../../styles/variables.scss";

.Pros {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0 !important;

  &__list {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;
    margin-top: 2vw;
    margin-bottom: 1vw;

    // overflow: hidden;
    &__item {
      width: 25vw;
      min-height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: white;
      padding: 2vw;
      margin: 0.5vw;
      border-radius: 20px;
      z-index: 2;
      box-shadow: rgba(59, 92, 135, 0.25) 0px 13px 27px -5px,
        rgba(6, 18, 39, 0.3) 0px 8px 16px -8px;
      // box-shadow: $background-overlay 0px 20px 30px -10px;

      &:nth-child(1) {
        width: 60%;
      }

      & > b {
        margin-bottom: 1rem;
        color: $main-color;
        font-size: $subtitle-size;
      }
      &__text {
        text-align: center;
      }
    }
  }
  &__info {
    margin-top: 5vw;
    width: 70%;
    & > h3 {
      color: $main-color;
      font-size: $subtitle-size;
      margin-bottom: 2vw;
    }
    &__text {
      &__wrapper {
        font-weight: 400;
        margin-left: 4vw;
        width: 90%;
        list-style: none;
        & > .el {
          margin-bottom: 2vw;
          color: black;

          b {
            width: 70%;
            display: block;
            margin-bottom: 1rem;
          }

          p {
            background-color: $lightest;
            border-radius: 2vw;
            padding: 2vw;
            margin-left: 2vw;
          }
        }
      }
    }
  }
}
@media (max-width: $little-screen) {
  .Pros {
    &__list {
      &__item {
        b {
          font-size: $little-screens-subtitle-size;
        }
      }
    }

    &__info {
      & > h3 {
        font-size: $little-screens-title-size;
      }
    }
  }
}

@media (max-width: $tablets) {
  .Pros {
    &__list {
      &__item {
        b {
          font-size: $tablets-title-size;
        }
        &__text {
          font-size: $tablets-text-size;
        }
      }
    }
    &__info {
      & > h3 {
        font-size: $tablets-title-size;
      }

      &__text {
        &__wrapper {
          & > .el {
            b {
              font-size: $tablets-subtitle-size;
            }

            p {
              font-size: $tablets-text-size;
            }
          }
        }
      }
    }
  }
}
@media (max-width: $mobile) {
  .Pros {
    &__list {
      flex-direction: column;
      align-items: center;
      &__item {
        width: 60%;
        padding: 3vw;
        min-height: 10vh;
        margin-bottom: 2vw;
        &:nth-child(1) {
          width: 60%;
        }
        b {
          font-size: $mobile-subtitle-size;
        }

        &__text {
          font-size: 0.8rem;
        }
      }
    }

    &__info {
      & > h3 {
        font-size: $mobile-title-size;
      }
      &__text {
        &__wrapper {
          margin-top: 1rem;
          & > .el {
            margin-bottom: 1rem;
            b {
              width: 90%;
              margin-bottom: 3vw;
              font-size: $mobile-subtitle-size;
            }

            p {
              font-size: $mobile-text-size;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $thinnest) {
  .Pros {
    &__list {
      &__item {
        b {
          font-size: $thinnest-title-size;
          margin-bottom: 2vw;
        }

        &__text {
          font-size: $thinnest-text-size;
        }
      }
    }
    &__info {
      &__text {
        &__wrapper {
          width: 100%;
          margin: 0;
          & > .el {
            margin-bottom: 1rem;
            b {
              width: 90%;
              margin-bottom: 3vw;
              font-size: $thinnest-subtitle-size;
            }

            p {
              font-size: $thinnest-text-size;
            }
          }
        }
      }
    }
  }
}
