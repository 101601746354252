@import "../../styles/variables.scss";
.Contacts {
  padding-top: 0 !important;
  padding-bottom: 6vw !important;
  &__container {
    width: 50%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > h2 {
      position: relative;

      z-index: 1;
    }
    &__form {
      margin-top: 2vw;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;

      &__inputs {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &__input-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        }

        & > p {
          width: 60%;
        }
      }
    }
  }
}
#submit {
  width: 50%;
  margin-top: 2vw;
  transition: all 0.5s ease-in;
  background-color: $main-color;
  color: white;

  &:hover {
    cursor: pointer;
  }

  &:active {
    background-color: $darkest;
  }
  &:disabled {
    background-color: rgb(153, 163, 173);
  }
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

textarea {
  box-sizing: border-box;
  height: 15vw;
  border-radius: 20px;
  padding: 2vw;
  width: 100%;
  border: none;

  background-color: $lightest;
  resize: none;
  margin: 1vw 0 3vw 0;
  &:focus {
    outline: none;
  }
}

input {
  margin-bottom: 2vw;
  margin-top: 1vw;
  padding: 1vw 2vw;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  border: none;
  background-color: $lightest;
  &:focus {
    outline: none;
  }
}

@media (max-width: 800px) {
  input,
  textarea,
  #submit {
    border-radius: 7px;
  }
}

@media (max-width: $tablets) {
  .Contacts {
    &__container {
      width: 70%;
    }
  }
}

@media (max-width: 600px) {
  input,
  textarea,
  #submit {
    border-radius: 6px;
  }
}

@media (max-width: $mobile) {
  #submit {
    width: 100%;
    height: 10vw;
  }
  textare {
    height: 40vw;
  }
}
