@import "../../styles/variables.scss";
.LCS {
  position: relative;
  background: url(../../assets/photo.png), rgba(255, 255, 255, 0.8);
  background-blend-mode: overlay;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 80vmax;
  min-height: 50vh;
  padding: 7vw 5vw !important;

  &__title {
    // color: $main-color;
    margin-bottom: 3vw;
  }

  &__pros {
    display: flex;
    width: 70%;
    margin: auto;
    flex-direction: column;
    &__item {
      z-index: 2;
      background-color: rgba(255, 255, 255, 0.7);
      padding: 2vw;
      border-radius: 3vw;
      margin: 1vw;
      &__title {
        color: $main-color;
        font-size: $subtitle-size;
        font-weight: 600;
        margin-bottom: 2vw;
      }
      &__text {
        margin-bottom: 1vw;

        ul {
          margin-left: 2vw;
        }
        li {
          margin-bottom: 2vw;
        }
      }
    }
  }
}

@media (max-width: $little-screen) {
  .LCS {
    &__pros {
      &__item {
        &__title {
          font-size: $little-screens-subtitle-size;
        }
        &__text {
          font-size: $little-screens-text-size;
        }
      }
    }
  }
}

@media (max-width: $tablets) {
  .LCS {
    &__pros {
      &__item {
        &__title {
          font-size: $tablets-subtitle-size;
        }
        &__text {
          font-size: $tablets-text-size;
        }
      }
    }
  }
}

@media (max-width: $mobile) {
  .LCS {
    margin-top: 6vw;
    background-size: 0;
    &__title {
      margin: 0;
    }
    &__pros {
      width: 100%;
      &__item {
        &__title {
          width: 70%;
          margin: 3vw auto;
          text-align: center;
          font-size: $mobile-subtitle-size;
        }
        &__text {
          width: 70%;
          margin: auto;
          font-size: $mobile-text-size;
        }
      }
    }
  }
}

@media (max-width: $thinnest) {
  .LCS {
    &__pros {
      width: 90%;
      &__item {
        &__title {
          width: 90%;
          text-align: left;
          font-size: $thinnest-subtitle-size;
        }
        &__text {
          width: 90%;
          font-size: $thinnest-text-size;
        }
      }
    }
  }
}
