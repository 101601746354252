$lightest: #e9f1f7;
$lighter: #aae9f1;
$main-color: #1971c3;
$darkest: #002c6f;
$links-hover: #003b7f;
$background-overlay: #001b49a4;

//sizes
$title-size: 1.6rem;
$subtitle-size: 1.4rem;
$text-size: 1rem;
$links-size: 1rem;

$little-screens-title-size: 1.4rem;
$little-screens-subtitle-size: 1.2rem;
$little-screens-text-size: 1rem;
$little-screens-links-size: 0.8rem;

$tablets-title-size: 1rem;
$tablets-subtitle-size: 0.8rem;
$tablets-text-size: 0.8rem;
$tablets-links-size: 0.6rem;

//mobile
$mobile-title-size: 1.2rem;
$mobile-subtitle-size: 1rem;
$mobile-text-size: 0.8rem;

$thinnest-title-size: 1rem;
$thinnest-subtitle-size: 0.8rem;
$thinnest-text-size: 0.6rem;

//screen resolutions
// $desktop: 1920px;
$little-screen: 1050px;
$tablets: 750px;
$mobile: 550px;
$thinnest: 380px;
