@import "../../styles/variables.scss";
.Footer {
  border: none !important;
  padding: 6vw 4vw !important;
  min-height: fit-content !important;
  background: linear-gradient(to bottom, $main-color, $darkest);
  overflow-x: hidden;
  &__main-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    font-weight: 200;

    & > * {
      width: 23%;
    }

    p,
    a,
    h3,
    li {
      color: white;
      & a {
        font-weight: 200;
        &:hover {
          color: rgb(132, 166, 209);
        }
      }
    }
    h3 {
      margin-bottom: 1vw;
    }
    ul {
      list-style: none;
      white-space: nowrap;
    }
    &__logo {
      display: flex;
      width: 20vw;
      align-self: center;
    }
  }
  &__phone-numbers {
    display: flex;
    flex-direction: column;
  }
  &__business {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    color: white;
  }
}

@media (max-width: $tablets) {
  .Footer {
    &__main-info {
      flex-wrap: wrap;
      p,
      a,
      h3,
      li {
        font-size: $tablets-text-size;
      }
    }
  }
}

@media (max-width: $mobile) {
  .Footer {
    &__main-info {
      flex-direction: column;
      align-items: center;
      align-content: center;
      & > * {
        width: unset;
      }

      div {
        width: 50%;
      }

      &__logo {
        align-self: flex-start;
        margin: auto;
        width: 80%;
        margin-bottom: 4vw;
      }
    }
    &__logo-container {
      width: 100%;
    }
    &__business {
      align-items: center;
      align-content: center;
      margin-bottom: 4vw;
    }
    &__phone-numbers {
      align-items: center;
      margin-bottom: 4vw;
    }
    &__adress {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-bottom: 4vw;
    }
  }
}

@media (max-width: $thinnest) {
  .Footer {
    &__business,
    &__phone-numbers,
    &__adress {
      font-size: $thinnest-text-size !important;
    }
    &__main-info {
      &__logo {
        width: 100%;
      }
    }
  }
}
