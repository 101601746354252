@import "../../styles/variables.scss";

.About {
  width: 100%;
  box-shadow: inset 0px -2px 0px 0px rgba(255, 255, 255, 0.653);
  background: linear-gradient(
      to bottom,
      $background-overlay,
      $background-overlay,
      rgba(255, 255, 255)
    ),
    url("../../assets/vents_background.jpeg");

  background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 3vw 0 !important;
  position: relative;
  overflow: hidden;

  & > img {
    position: absolute;
    width: 50vw;
    bottom: 0;
    left: 0rem;
    height: 100%;
    z-index: 0;
  }

  &__info {
    width: 100%;
    display: flex;
    flex-direction: row;
    background: transparent;
    align-items: center;
    justify-content: space-between;
    margin: 5vw 0;
    z-index: 1;
    &__mobile {
      display: none;
    }

    &__slogan {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      margin: auto;
      z-index: 2;
    }

    &__text {
      width: 30%;
      padding: 5vw;
      & > h2,
      h1 {
        color: $main-color;
        align-self: flex-start;
        // animation: fade-in 2s ease forwards;
      }
      & > h2 {
        margin-top: 2vw;
        font-weight: 800;
      }
      & > h3 {
        color: $main-color;
        margin-top: 2vw;
        font-weight: 400;
      }
    }
  }
}

.slogan {
  display: flex;
  padding: 0.7vw;
  & > img {
    height: calc(5vw + 20px);
  }
}

#slogan1 {
  opacity: 0;
  animation: slide-left 1s ease forwards 1s;
}
#slogan2 {
  opacity: 0;
  margin-left: 4vw;
  animation: slide-left 1s ease forwards 2s;
}
#slogan3 {
  opacity: 0;
  margin-left: 8vw;
  animation: slide-left 1s ease forwards 3s;
}

@keyframes slide-left {
  from {
    transform: translate(200%);
  }

  to {
    opacity: 100%;
    transform: translate(0%);
    overflow: hidden;
  }
}

// @keyframes fade-in {
//   to {
//     opacity: 100%;
//   }
// }

@media (max-width: 1050px) {
  .slogan {
    & > img {
      height: calc(6vw + 20px);
    }
  }
}

@media (max-width: $mobile) {
  .About {
    width: 100%;
    height: 100%;
    & > img {
      display: none;
    }
    &__info {
      flex-direction: column;
      &__text {
        width: 80%;
        border-radius: 20px;

        text-align: center;
        & > h1,
        h2 {
          color: white;
        }
      }
      &__slogan {
        display: none;
      }
      &__mobile {
        display: flex;
        justify-content: center;
        & > img {
          width: 75%;
        }
      }
    }
  }
}
