@import "../../styles/variables.scss";
.Card {
  display: flex;
  width: 24vw;
  height: 18vw;
  padding: 0.7vw;
  align-items: center;
  background-color: transparent;
  perspective: 1000px;
  margin: 1vw;
  transition: all 1s ease;

  &__inner {
    position: relative;
    width: 100%;
    height: 100%;

    transition: all 1.5s ease;
    //THIS IS VERY IMPORTANT
    transform-style: preserve-3d;
  }
  &__back,
  &__front {
    border-radius: 20px;
    position: absolute;
    display: flex;
    transition: all 1s ease;

    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  &__front {
    text-align: center;
    & > h1 {
      white-space: pre-wrap;
      font-size: $subtitle-size;
      padding: 0 1vw;
      font-weight: 600;
    }
    box-shadow: rgba(59, 92, 135, 0.25) 0px 13px 27px -5px,
      rgba(6, 18, 39, 0.3) 0px 8px 16px -8px;
    color: white;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__back {
    display: flex;
    transform: rotateY(180deg);
    align-items: flex-end;
    background-repeat: no-repeat;
    background-size: 150%;

    &__info {
      width: 100%;
      padding: 1rem;
      font-size: 0.8rem;
      background-color: white;
      color: transparent;
      border-radius: 0 0 20px 20px;
      box-shadow: rgba(59, 92, 135, 0.25) 0px 13px 27px -5px,
        rgba(6, 18, 39, 0.3) 0px 8px 16px -8px;
      transition: all 2s ease;
    }

    &__link{
      display: block;
      margin-top: 1rem;
    }
  }
}

.Card:hover {
  transition: all 1.5s ease;

  .Card__inner {
    transform: rotateY(180deg);
  }
  .Card__back {
    &__info {
      color: black;
      transition: color 2s ease 0.5s;
    }
  }
}

@media (max-width: $little-screen) {
  .Card {
    &__front {
      & > h1 {
        font-size: $little-screens-text-size;
      }
    }

    &__back {
      &__info {
        font-size: 0.6rem;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .Card {
    width: 38vw;
    height: 25vw;
  }

  #text-card {
    width: 38vw;
    padding: 2vw;
    margin: 0;
  }
}

@media screen and (max-width: $mobile) {
  .Card {
    width: 100%;
    margin: 2vw;

    &__front {
      & > h1 {
        font-size: $mobile-title-size;
      }
    }
  }
  .Card:hover {
    transition: all 1.5s ease steps(20);
    width: 100%;
    height: 30vh;
  }
  .Card__back {
    &__info {
      width: 100%;
      font-size: $mobile-text-size;
    }
  }
}

@media (max-width: $thinnest) {
  .Card {
    &__front {
      & > h1 {
        font-size: $thinnest-subtitle-size;
      }
    }
  }
  .Card__back {
    &__info {
      font-size: $thinnest-text-size;
    }
  }
}
