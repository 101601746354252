@import "../../styles/variables.scss";
.Nav {
  transition: none;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  z-index: 5;
  & > .desktop {
    width: 100%;
    padding: 0 3vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__container {
    display: flex;
    width: 100%;
    justify-content: right;
    align-items: center;
  }
  &__phone-numbers {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0.8vw;
    width: fit-content;
    white-space: nowrap;
    &__item {
      font-size: 0.8rem;
      font-weight: 600;
      color: $main-color;
      &:hover {
        color: $main-color;
      }
    }
  }
  &__items {
    list-style: none;
    display: flex;
    justify-content: space-between;

    &__item {
      white-space: nowrap;
      display: flex;
      align-self: center;
      margin-right: 2vw;
      & > a {
        font-weight: 600;
        &:hover {
          color: $main-color;
        }
      }
    }
  }
  &__logo {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: left;
    & > img {
      width: 7rem;
      padding: 0.5vw;
    }
  }
}

@media (max-width: $little-screen) {
  .Nav {
    &__items__item > a {
      font-size: $little-screens-links-size;
    }
    &__phone-numbers__item {
      font-size: 0.6rem;
    }
    &__logo > img {
      width: 5rem;
    }
  }
}

@media (max-width: $tablets) {
  .Nav {
    & > .desktop {
      padding: 0 2vw;
    }
    &__items__item > a {
      font-size: $tablets-links-size;
    }
    &__phone-numbers__item {
      font-size: 0.5rem;
    }
    &__logo > img {
      width: 4rem;
    }
  }
}

@media (max-width: $mobile) {
  .Nav {
    display: none;
  }
}
