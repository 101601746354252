@import "../../styles/variables.scss";

.swiper {
  --swiper-theme-color: #1971c3;
  --swiper-navigation-size: 4.5vw;
  --swiper-pagination-bullet-width: 0.8vw;
  --swiper-pagination-bullet-height: 0.8vw;
  width: 80%;
  height: 45vw;
  margin-bottom: 6vw;
  border-radius: 20px;
  display: flex !important;
  position: relative;
}

.swiper-pagination {
  width: 100%;
  height: 2vw;
}
.swiper-wrapper {
  align-items: center;
}
.swiper-slide {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;

  img {
    border-radius: 10px;
    object-fit:contain;
    width: 50vw;
    height: 30vw;
    margin: 1vw auto;
  }
}
.swiper-pagination {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 1vw;
  position: absolute;
  padding-top: 2vw;
}
.swiper-button-prev,
.swiper-button-next {
  margin: 2vw;
}

.swiper-slide-description {
  width: 80%;
  text-align: center;
  font-size: 1.2vw;
}

@media (max-width: $tablets) {
  .swiper {
    width: 90%;
    height: 52vw;
    --swiper-pagination-bullet-width: 1.4vw;
    --swiper-pagination-bullet-height: 1.4vw;
    --swiper-navigation-size: 6vw;
  }
  .swiper-slide {
    img {
      border-radius: 10px;
      object-fit: cover;
      width: 60vw;
      height: 35vw;
      margin: 1vw auto;
    }
  }
}

@media (max-width: $mobile) {
  .swiper {
    width: 95%;
    height: 56vw;
    --swiper-pagination-bullet-width: 1.6vw;
    --swiper-pagination-bullet-height: 1.6vw;
    --swiper-navigation-size: 6vw;
  }

  .swiper-slide-description {
    margin: 2vw auto;
    font-size: 2.5vw;
  }
}
@media (max-width: $thinnest) {
  .swiper {
    width: 95%;
    height: 60vw;
    --swiper-pagination-bullet-width: 2vw;
    --swiper-pagination-bullet-height: 2vw;
    --swiper-navigation-size: 6vw;
  }

  .swiper-slide-description {
    margin: 2vw auto;
    font-size: 3vw;
  }
}
