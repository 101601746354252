@import "/src/styles/variables.scss";
.Specializations {
  height: auto;
  position: relative;
  z-index: 2;

  &__title {
    color: $main-color;
  }
  &__info {
    margin: 2vw;
    color: $background-overlay;
  }
  &__list {
    width: 95%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: space-between;
    flex-flow: wrap;
  }
}
#text-card {
  color: $main-color;
  width: 60%;
  margin-bottom: 1vw;
  font-weight: 200;
  text-align: center;
  margin-right: 2rem;
}
#assemblies {
  .Card__front {
    background-image: url("../../assets/card-backgrounds/Assemblies/front.png");
  }
  .Card__back {
    background-image: url("../../assets/card-backgrounds/Assemblies/back.png");
  }
}
#automation {
  .Card__front {
    background-image: url("../../assets/card-backgrounds/Automation/front.png");
  }
  .Card__back {
    background-image: url("../../assets/card-backgrounds/Automation/back.png");
  }
}
#cold-room-manufacturing {
  .Card__front {
    background-image: url("../../assets/card-backgrounds/Cold room manufacturing/front.png");
  }
  .Card__back {
    background-image: url("../../assets/card-backgrounds/Cold room manufacturing/back.png");
  }
}
#maintenance {
  .Card__front {
    background-image: url("../../assets/card-backgrounds/Maintenance/front.png");
  }
  .Card__back {
    background-image: url("../../assets/card-backgrounds/Maintenance/back.png");
  }
}
#refrigeration-systems {
  .Card__front {
    background-image: url("../../assets/card-backgrounds/Refrigeration systems/front.png");
  }
  .Card__back {
    background-image: url("../../assets/card-backgrounds/Refrigeration systems/back.png");
  }
}
#ventilation {
  .Card__front {
    background-image: url("../../assets/card-backgrounds/Ventilation/front.png");
  }
  .Card__back {
    background-image: url("../../assets/card-backgrounds/Ventilation/back.png");
  }
}
#conditioning {
  .Card__front {
    background-image: url("../../assets/card-backgrounds/Conditioning/front.png");
  }
  .Card__back {
    background-image: url("../../assets/card-backgrounds/Conditioning/back.png");
  }
}
#heating {
  .Card__front {
    background-image: url("../../assets/card-backgrounds/Heating/front.png");
  }
  .Card__back {
    background-image: url("../../assets/card-backgrounds/Heating/back.png");
  }
}
#temperature-control {
  .Card__front {
    background-image: url("../../assets/card-backgrounds/Temperature control/front.png");
  }
  .Card__back {
    background-image: url("../../assets/card-backgrounds/Temperature control/back.png");
  }
}
#plumbing {
  .Card__front {
    background-image: url("../../assets/card-backgrounds/Plumbing/front.png");
  }
  .Card__back {
    background-image: url("../../assets/card-backgrounds/Plumbing/back.png");
  }
}
#sewerage {
  .Card__front {
    background-image: url("../../assets/card-backgrounds/Sewerage/front.png");
  }
  .Card__back {
    background-image: url("../../assets/card-backgrounds/Sewerage/back.png");
  }
}
#drilling {
  .Card__front {
    background-image: url("../../assets/card-backgrounds/Drilling/front.png");
  }
  .Card__back {
    background-image: url("../../assets/card-backgrounds/Drilling/back.png");
  }
}
#lcs {
  .Card__front {
    background-image: url("../../assets/card-backgrounds/LCS/front.png");
  }
  .Card__back {
    background-image: url("../../assets/card-backgrounds/LCS/back.png");
  }
}

@media screen and (max-width: 500px) {
  .Specializations {
    &__list {
      justify-content: center;
      margin: auto;
    }
  }
}

@media (max-width: $tablets) {
}
